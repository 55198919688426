import axios from 'axios';

export default {
  getEstimationsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/Estimations', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addEstimation: (estimation) => axios.post('/api/Estimations', estimation),
  updateEstimation: (estimation) => axios.put(`/api/Estimations/${estimation.id}`, estimation),
  deleteEstimation: (id) => axios.delete(`/api/Estimations/${id}`),  
};
