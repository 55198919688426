<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Оценки действия
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
        <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="estimationForm" :model="estimation" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="estimation.name" id="estimation-modal--name_input"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="Код:">
        <el-input v-model="estimation.code" id="estimation-modal--code_input"></el-input>
      </el-form-item>
      <el-form-item prop="isActual" label="Признак актуальности:">
        <el-checkbox v-model="estimation.isActual"></el-checkbox>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input v-model="estimation.mRid"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import estimationsApi from '@/api/nsi/estimations';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'EstimationModal',
  props: ['value', 'estimation'],
  mixins: [validationRules],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    async addEstimation(estimation) {
       await estimationsApi.addEstimation(estimation);
      this.$emit('on-create');
    },
    async updateEstimation(estimation) {
      await estimationsApi.updateEstimation(estimation);
      this.$emit('on-update');
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async validateForm() {
      const valid = await this.$refs.estimationForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.estimation.id ? this.updateEstimation : this.addEstimation;
      this.loading = true;
      await request(this.estimation);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
