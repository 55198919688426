<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col :span="12">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-table v-loading="loading" ref="table"
                :data="estimations"
                style="width: 100%;"
                :height="tableHeight"
                :default-sort = "{prop: 'name', order: 'ascending'}"
                @sort-change="handleSortChange">
        <el-table-column v-if="userCanEdit" label="" width="50">
          <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="Наименование"
                         sortable="custom"
                         min-width="75">
        </el-table-column>
        <el-table-column prop="code"
                         label="Код"
                         sortable="custom"
                         min-width="25">
        </el-table-column>
        <el-table-column prop="isActual"
                         label="Признак актуальности"
                         sortable="custom"
                         min-width="25"
                         :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
            <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <estimation-modal @on-update="getEstimations()" @on-create="getEstimations()" v-model="dialogVisible" :estimation="dialogEstimation"></estimation-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import estimationsApi from '@/api/nsi/estimations';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import EstimationModal from './EstimationModal';

export default {
  name: 'EstimationsTable',
  props: {
    filterModel: { type: Object, default: () => { } },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, tableResize, xlsUpDownHelper],
  components: { pagination, EstimationModal },
  data() {
    return {
      loading: false,
      estimations: [],
      pageNumber: 1,
      pageSize: 20,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogEstimation: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiLocalEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getEstimations();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getEstimations();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const { table } = this.$refs;
      const tableColumns = table.store.states.columns;
      const res = await estimationsApi.getEstimationsWithPagination(
        this.pageNumber,
        this.totalCount,
        this.filterModel.nameFilter,
        this.sortField,
        this.sortDesc
      );
      if (res.data) {
        await this.exportToExcel('Оценки', res.data.items, tableColumns);
      }
      this.loading = false;
    },
    async getEstimations() {
      this.loading = true;
      const res = await estimationsApi.getEstimationsWithPagination(
        this.pageNumber,
        this.pageSize,
        this.filterModel.nameFilter,
        this.sortField,
        this.sortDesc
      );
      if (res.data) {
        this.estimations = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEstimations();
    },
    async handleCurrentChange() {
      await this.getEstimations();
    },
    async handleSortChange(event) {
      this.sortField = event.prop;
      this.sortDesc = event.order === 'descending';
      await this.getEstimations();
    },
    handleEdit(index, estimation) {
      this.dialogEstimation = cloneDeep(estimation);
      this.dialogVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate() {
      this.dialogEstimation = {
        id: null, name: null, code: null,
      };
      this.dialogVisible = true;
    },
    handleDelete(index, estimation) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await estimationsApi.deleteEstimation(estimation.id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        } else {
          await this.getEstimations();
        }

        this.loading = false;
      });
    },
  }
};
</script>

<style scoped>

</style>
